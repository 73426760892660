.slide-modal {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9990;
  top: 0;
  left: 0;

  &--bg {
    width: inherit;
    height: inherit;
    background: rgba($color: #000000, $alpha: 0.7);
    position: absolute;
    z-index: 9991;
  }

  &--container {
    width: 100%;
    max-width: 780px;
    max-height: 100%;
    position: absolute;
    z-index: 9993;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 991.98px) {
      width: 100%;
      max-width: calc(100% - 140px);
    }
    @media screen and (max-width: 575.98px) {
      background: white;
      width: 100%;
      max-width: 100%;
    }
  }

  &--content {
    background: white;
    width: 100%;
    padding: 30px;
    position: relative;
    z-index: 9993;
    // animation: slideModalAnimate .4s;
    transition: all 0.1s ease-in;

    @media screen and (max-width: 991.98px) {
      padding: 20px;
    }
    @media screen and (max-width: 575.98px) {
      max-width: calc(100% - 60px);
      padding: 10px 0;
      margin: 0 auto;
    }

    .modal-content {
      width: 100%;
      min-height: 420px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (max-width: 576.98px) {
        min-height: 0;
        max-height: calc(var(--vh, 1vh) * 100 - 120px);
      }

      @media screen and (max-width: 320px) {
        min-height: 0;
        max-height: calc(var(--vh, 1vh) * 100 - 140px);
      }

      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .modal-close {
    color: white;
    position: absolute;
    z-index: 9994;
    bottom: 0;
    right: 0;
    transform: translate(0, 110%);
    font-style: normal;
    font-size: 24px;
    font-family: Arial, Baskerville, monospace;
    line-height: 24px;
    cursor: pointer;
    @media (max-width: 834px) {
      bottom: -30px
    }

    &::before {
      content: '閉じる';
      font-size: 20px;
    }
  }

  &--arrow {
    width: calc(100% + 200px);
    display: flex;
    position: absolute;
    top: 50%;
    left: -100px;
    z-index: 9992;
    transform: translate(0, -50%);

    @media screen and (max-width: 991.98px) {
      width: calc(100% + 140px);
      left: -70px;
    }
    @media screen and (max-width: 575.98px) {
      width: 100%;
      bottom: -50%;
      left: 0;
    }

    .modal-arrow {
      display: flex;

      @media screen and (max-width: 575.98px) {
        height: 100%;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        color: white;
        width: 80px;
        height: 80px;
        padding: 15px;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          transform: scale(1.2);
          transition: all 0.2s ease-in;
        }

        @media screen and (max-width: 991.98px) {
          width: 60px;
          height: 60px;
          padding: 10px;
        }
        @media screen and (max-width: 575.98px) {
          color: black;
          width: 30px;
          height: 30px;
          padding: 0px;
          transform: scale(1) !important;
        }
      }
    }

    .arrow-left {
      position: absolute;
      left: 0;
    }

    .arrow-right {
      position: absolute;
      right: 0;
    }
  }

  &.modal-hide {
    display: none !important;
  }
}

@keyframes slideModalAnimate {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
